<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="box">
			<Title title="发票列表" />
			<div class="ct_box">
				<div class="search-status_box flex">
					<div class="search_box flex">
						<el-date-picker value-format="timestamp" @change="getList()" v-model="time" type="daterange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
						<!-- <div class="order_search flex">
							<img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
							<el-input class="order_search_inp" v-model="title" placeholder="输入标题/订单号"></el-input>
							<button class="search_but" @click="getList">搜索</button>
						</div> -->
					</div>
					<ul class="status_ul flex">
						<li :class="status.title == item.title ? 'active' : ''" v-for="(item, index) in statusUl"
							:key="index" @click="statusShow(item)">
							{{ item.title }}
						</li>
					</ul>
				</div>
				<table class="table">
					<thead>
						<tr>
							<!-- <td>单号</td> -->
							<td>类型</td>
							<td>抬头</td>
							<td>开票金额</td>
							<td>状态</td>
							<td>申请时间</td>
							<!-- <td>详情</td> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in tableData" :key="index">
							<!-- <td>{{ item.order_ids }}</td> -->
							<td>{{ item.type == 1 ? '普通发票(1%税点)' : item.type == 2 ? '增值税专用发票(6%税点)' : '电子发票(1%税点)' }}
							</td>
							<td>{{ item.header }}</td>
							<td>{{ item.amount }}</td>
							<td
								:style="{ color: item.status == 0 ? '#333' : item.status == 1 ? '#FF8400' : item.status == 2 ? '#00B052' : '#FF0000' }">
								{{ item.status == 0 ? '待审核' : item.status == 1 ? '开票中' : item.status == 2 ? '已完成' :
		'被拒单' }}
							</td>
							<!-- <td></td> -->
							<td>{{ item.ctime }}</td>
						</tr>
					</tbody>
				</table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
			</div>
		</div>
	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle.vue'
export default {
	components: {
		Title,
	},
	mounted() {
		this.$store.commit('childTab', '发票列表')
		this.getList()
	},
	data() {
		return {
			time: '',
			statusUl: [
				{
					id: '',
					title: '全部',
				},
				{
					id: 0,
					title: '待审核',
				}, {
					id: 1,
					title: '开票中'
				}, {
					id: 2,
					title: '已完成'
				}, {
					id: 3,
					title: '被拒单'
				}],
			status: {
				id: '',
				title: '全部',
			},
			tableData: [],
			page: 1,
			total_page: 0, //分页总页数
			count: 0, //总条数
			fullscreenLoading: false, //loding

		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.listFn()
		},
		statusShow(item) {
			this.status = item
			this.getList()
		},
		getList() {
			this.fullscreenLoading = true
			let data = {}
			if (this.status.id !== '') {
				data.status = this.status.id
			}
			if (this.time != '') {
				data.start_time = this.time[0] / 1000
				data.end_tiem = this.time[1] / 1000
			}
			this.curlPost('/api/users/invoice', data).then(res => {
				console.log(res);
				if (res.data.code) {
					this.tableData = res.data.data.list
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
				}
				this.fullscreenLoading = false
			})
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/Invoice'
</style>